@import "~react-datepicker/dist/react-datepicker.css";

html,
body {
  font-size: 14px;
  min-width: 0;
  font-family: Lato, Helvetica, sans-serif;
}

body ::-webkit-scrollbar-thumb,
body ::-webkit-scrollbar-track {
  border-radius: 1rem 1rem 1rem 1rem;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

@media print {
  #root .no-print.no-print.no-print.no-print {
    display: none !important;
  }
}
